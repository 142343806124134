<template>
    <div>
        <!-- 专栏课基本资料-->
        <el-row :gutter="30">
            <el-col :span="11">
                <el-image v-show="topic.imgUrl" :src="topic.imgUrl"
                          style="height: 260px; width: 100%; border-radius: 10px;"/>
            </el-col>
            <el-col :span="13">
                <div style="font-size: 24px; margin-top: 20px; display: flex; align-items: center; height: 60px;">
                    {{ topic.title }}
                    <el-tag v-if="isBuy" type="success" style="margin-left: 15px;">
                        已购买
                    </el-tag>
                </div>
                <div style="height: 200px;">
                    <div v-if="!isBuy">
                        <el-button v-if="!(isLimitQuantity && isLimitEnd)"
                                   v-loading="buyLoading"
                                   class="shopping"
                                   @click="toBuy()"
                                   style="position:absolute; bottom: 20px;">
                            {{ topic.price === 0 ? '免费领取' : ('￥' + ((topic.price / 100).toFixed(2)) + '购买') }}
                            {{ isLimitQuantity ? '（仅剩' + remainQuantity + '份）' : '' }}
                        </el-button>
                        <el-button v-else disabled type="warning" style="font-size: 18px;">
                            限量已结束，敬请期待！
                        </el-button>
                    </div>
                </div>
            </el-col>
        </el-row>

        <!-- 专栏课详情-->
        <el-tabs v-model="packageListType" style="margin-top: 30px;">
            <el-tab-pane>
                <div slot="label" style="font-size: 20px; margin-bottom: 10px;">
                    详情介绍
                </div>
            </el-tab-pane>
            <el-tab-pane>
                <div slot="label" style="font-size: 20px; margin-bottom: 10px;">
                    专栏内容
                </div>
            </el-tab-pane>
        </el-tabs>

        <!-- 内容区-->
        <page-main :class="{showPaper: (packageListType === '1' && isBuy)}">
            <!-- 专栏详情 -->
            <div v-if="packageListType === '0'">
                <div style="text-align: center; padding: 30px;">
                    <el-image v-if="topic.detailImgUrl" :src="topic.detailImgUrl" style="width: 80%; height: 100%;"/>
                </div>
            </div>

            <!--  专栏内容 -->
            <div v-show="packageListType === '1'">
                <!-- 未购买的内容-->
                <div v-show="!isBuy">
                    <div v-for="(packageSingle, key) in packageData" :key="key" @click="toShow()"
                         style="display: flex; justify-content: center;">
                        <el-card :body-style="{ padding: '0px' }" shadow="hover"
                                 style="width: 385px; height: 251px; border-radius: 8px; margin: 15px;">
                            <img :src="packageSingle.imgUrl" style="width: 100%; height: 150px;">
                            <div style="padding: 14px;">
                                <span>{{ packageSingle.title }}</span>
                                <el-row style="margin-top: 20px;">
                                    <el-col :span="6">
                                        <el-tag type="info" size="small">任务包</el-tag>
                                    </el-col>
                                    <el-col :span="18">
                                        <div style="text-align: right;">
                                            共{{ packageSingle.dayNum }}期
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-card>
                    </div>
                </div>

                <!-- 已购买内容 -->
                <div v-if="isBuy">

                    <!-- 套卷列表 -->
                    <subjective-paper-list :data-list="subjectivePaperList"
                                           :limit-time="topic.isLimitContentTime"
                                           :start-time="topic.contentStartTime"
                                           :end-time="topic.contentEndTime"
                                           :is-data="true"
                    />
                </div>
            </div>
        </page-main>

        <!-- PC 支付二维码  -->
        <el-dialog title="支付二维码" :visible.sync="show_pay_code" width="400px">
            <div style="display: flex; justify-content: center;">
                <div>
                    <h4>请使用微信扫描下方二维码支付</h4>
                    <vue-qrcode :value="codeUrl" :options="{ width: 200 }"></vue-qrcode>
                    <div style="margin-bottom: 20px; margin-top: 30px;">
                        <el-button style="background: linear-gradient(to right, #ff6034, #ee0a24); color: #ffffff"
                                   @click="$router.push({name: 'reload'})">
                            已支付成功？点击刷新页面
                        </el-button>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
// https://github.com/fengyuanchen/vue-qrcode
import VueQrcode from '@chenfengyuan/vue-qrcode'
import {Message} from 'element-ui';
import SubjectivePaperList from "@/views/my/components/subjectivePaperList";

export default {
    name: "detail",
    components: {VueQrcode, SubjectivePaperList},
    data() {
        return {
            topic: {
                imgUrl: '',
                isLimitContentTime: false,
                contentStartTime: '',
                contentEndTime: ''
            },
            packageData: [],
            isBuy: true,
            packageListType: '0',
            codeUrl: '',
            show_pay_code: false,
            subjectivePaperList: [],
            isLimitQuantity: false,
            remainQuantity: 0,
            isLimitEnd: false,
            buyLoading: false,
        }
    },
    beforeCreate() {
        document.querySelector('body').setAttribute('style', 'background:#f5f6fa')
    },
    mounted() {
        if (!this.$route.query.id) {
            this.$router.push({name: 'index'})
        }
        this.handleGetData()
    },
    methods: {
        toShow() {
            Message.success("购买成功后，即可自动获取主观题套卷~")
        },
        // 获取单一的专栏课详情
        handleGetData() {
            // 获取单个专栏课
            this.$api.get('/topic/getSingleTopic', {
                params: {
                    id: this.$route.query.id
                }
            }).then(res => {
                this.topic = res.data.topic
                this.packageData = res.data.packageList
                this.isBuy = res.data.isBuy
                if (this.isBuy) {
                    this.packageListType = '1'
                    this.handleGetPackListSubjectivePaper(this.$route.query.id)
                }
                if (res.data.topic.isLimitQuantity) {
                    this.isLimitQuantity = true
                    this.remainQuantity = res.data.remainQuantity
                    this.isLimitEnd = !this.remainQuantity
                }
            })
        },
        // 获取已购买的专栏课中的套卷列表
        handleGetPackListSubjectivePaper(id) {
            this.$api.get('/topic/getTopicSubjectivePaper', {
                params: {id: id}
            }).then(res => {
                this.subjectivePaperList = res.data
            })
        },
        // 点击购买
        toBuy() {
            this.buyLoading = true
            // 用户获取订单接口
            this.$api.get('/pay/getPayOrder', {
                params: {
                    paySource: 2,
                    topicId: this.$route.query.id,
                    payType: 0
                }
            }).then(res => {
                // 查看是否购买过或者直接免费获取
                if (!res.data.isBuy) {
                    this.codeUrl = res.data.body.code_url
                    this.show_pay_code = true
                    this.buyLoading = false
                }
                this.handleGetData()
            }).catch(err => {
                this.buyLoading = false
                console.log(err)
                this.$message.error(err.msg || '获取支付订单失败，请稍后再试')
            })
        }
    }
}
</script>

<style scoped lang="scss">
.showPaper {
    background-color: #f5f6fa;
    box-shadow: none;
}

.shopping {
    background: linear-gradient(50deg, #FBD786, #f7797d);
    color: #ffffff;
    width: 250px;
    height: 50px;
    font-size: 17px;
}
</style>